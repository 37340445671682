
export interface IFile {
    original: string,
    thumbnail: string,
    // originalTitle: file.title,
    // thumbnailTitle : file.title,
    thumbnailClass: 'galleryThumbnail',
    description: string, // || file.title,
}

const listFiles = async (): Promise<IFile[]> => {
    const url = new URL("https://www.googleapis.com/drive/v2/files");
    const params: {[key: string]: string} = {
        key: 'AIzaSyAJHJ96vr4siPvrFsQO9yyac4MH1KrKpDw',
        q: "'1jqLTNedbG8m-m5RtzdWNqXx8HE4O8gZt' in parents"
    }

    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

    return fetch(url.toString())
        .then(result => result.json())
        .then(data => {
            return data.items.map((file: any) => ({
                original: file.webContentLink.replace('&export=download', ''),
                thumbnail: file.thumbnailLink,
                // originalTitle: file.title,
                // thumbnailTitle : file.title,
                thumbnailClass: 'galleryThumbnail',
                description: file.description, // || file.title,
            }));
        });
}

export {listFiles};
