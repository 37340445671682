import {DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import React from "react";

const Datenschutz = () => {

    return (
        <>
            <DialogTitle>
                Datenschutzerklärung
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Verantwortlicher für die Datenverarbeitung ist:<br/>
                    <br/>
                    Salisch Gastro GmbH<br/>
                    Rathausplatz 2<br/>
                    85406 Zolling<br/>
                    Deutschland<br/>
                    <br/>
                    salisch.gastro@gmail.com<br/>
                    +49 (0)8167 3251349<br/>
                    <br/>
                    Wir freuen uns über Ihr Interesse an unserem Online-Shop. Der Schutz Ihrer Privatsphäre ist für
                    uns sehr wichtig. Nachstehend informieren wir Sie ausführlich über den Umgang mit Ihren
                    Daten.<br/>
                    <br/>
                    <br/>
                    <br/>
                    1. Zugriffsdaten und Hosting<br/>
                    <br/>
                    Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Bei jedem Aufruf
                    einer Webseite speichert der Webserver lediglich automatisch ein sogenanntes Server-Logfile, das
                    z.B. den Namen der angeforderten Datei, Ihre IP-Adresse, Datum und Uhrzeit des Abrufs,
                    übertragene Datenmenge und den anfragenden Provider (Zugriffsdaten) enthält und den Abruf
                    dokumentiert.<br/>
                    <br/>
                    Diese Zugriffsdaten werden ausschließlich zum Zwecke der Sicherstellung eines störungsfreien
                    Betriebs der Seite sowie der Verbesserung unseres Angebots ausgewertet. Dies dient gemäß Art. 6
                    Abs. 1 S. 1 lit. f DSGVO der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden
                    berechtigten Interessen an einer korrekten Darstellung unseres Angebots. Alle Zugriffsdaten
                    werden spätestens sieben Tage nach Ende Ihres Seitenbesuchs gelöscht.<br/>
                    <br/>
                    Hostingdienstleistungen durch einen Drittanbieter<br/>
                    <br/>
                    Im Rahmen einer Verarbeitung in unserem Auftrag erbringt ein Drittanbieter für uns die Dienste
                    zum Hosting und zur Darstellung der Webseite. Alle Daten, die im Rahmen der Nutzung dieser
                    Webseite oder in dafür vorgesehenen Formularen im Onlineshop wie folgend beschrieben erhoben
                    werden, werden auf seinen Servern verarbeitet. Eine Verarbeitung auf anderen Servern findet nur
                    in dem hier erläuterten Rahmen statt.<br/>
                    <br/>
                    Dieser Dienstleister sitzt innerhalb eines Landes der Europäischen Union oder des Europäischen
                    Wirtschaftsraums.<br/>
                    <br/>
                    2. Datenerhebung und -verwendung zur Vertragsabwicklung, Kontaktaufnahme und bei Eröffnung eines
                    Kundenkontos<br/>
                    <br/>
                    Wir erheben personenbezogene Daten, wenn Sie uns diese im Rahmen Ihrer Bestellung oder bei einer
                    Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) freiwillig mitteilen.
                    Pflichtfelder werden als solche gekennzeichnet, da wir in diesen Fällen die Daten zwingend zur
                    Vertragsabwicklung, bzw. zur Bearbeitung Ihrer Kontaktaufnahme benötigen und Sie ohne deren
                    Angabe die Bestellung bzw. die Kontaktaufnahme nicht versenden können. Welche Daten erhoben
                    werden, ist aus den jeweiligen Eingabeformularen ersichtlich. Wir verwenden die von ihnen
                    mitgeteilten Daten gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO zur Vertragsabwicklung und Bearbeitung
                    Ihrer Anfragen.<br/>
                    <br/>
                    Soweit Sie hierzu Ihre Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO erteilt haben, indem
                    Sie Sich für die Eröffnung eines Kundenkontos entscheiden, verwenden wir Ihre Daten zum Zwecke
                    der Kundenkontoeröffnung.
                    Nach vollständiger Abwicklung des Vertrages oder Löschung Ihres Kundenkontos werden Ihre Daten
                    für die weitere Verarbeitung eingeschränkt und nach Ablauf der steuer- und handelsrechtlichen
                    Aufbewahrungsfristen gelöscht, sofern Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten
                    eingewilligt haben oder wir uns eine darüber hinausgehende Datenverwendung vorbehalten, die
                    gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren. Die Löschung Ihres
                    Kundenkontos ist jederzeit möglich und kann entweder durch eine Nachricht an die unten
                    beschriebene Kontaktmöglichkeit oder über eine dafür vorgesehene Funktion im Kundenkonto
                    erfolgen.<br/>
                    <br/>
                    3. Datenweitergabe<br/>
                    <br/>
                    Zur Vertragserfüllung gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO geben wir Ihre Daten an das mit der
                    Lieferung beauftragte Versandunternehmen weiter, soweit dies zur Lieferung bestellter Waren
                    erforderlich ist. Je nach dem, welchen Zahlungsdienstleister Sie im Bestellprozess auswählen,
                    geben wir zur Abwicklung von Zahlungen die hierfür erhobenen Zahlungsdaten an das mit der
                    Zahlung beauftragte Kreditinstitut und ggf. von uns beauftragte Zahlungsdienstleister weiter
                    bzw. an den ausgewählten Zahlungsdienst. Zum Teil erheben die ausgewählten Zahlungsdienstleister
                    diese Daten auch selbst, soweit Sie dort ein Konto anlegen. In diesem Fall müssen Sie sich im
                    Bestellprozess mit Ihren Zugangsdaten bei dem Zahlungsdienstleister anmelden. Es gilt insoweit
                    die Datenschutzerklärung des jeweiligen Zahlungsdienstleisters.<br/>
                    <br/>
                    Zur Bestell- und Vertragsabwicklung setzen wir zudem ein externes Warenwirtschaftssystem ein.
                    Die insoweit stattfindende Datenweitergabe bzw. Verarbeitung basiert auf einer
                    Auftragsverarbeitung.<br/>
                    <br/>
                    Wir setzen einen Zahlungsdienstleister ein, der seinen Sitz in einem Staat außerhalb der
                    Europäischen Union hat. Die Übermittlung personenbezogener Daten an dieses Unternehmen erfolgt
                    lediglich im Rahmen der Notwendigkeit zur Vertragserfüllung.<br/>
                    <br/>
                    4. E-Mail-Newsletter<br/>
                    <br/>
                    E-Mail-Werbung mit Anmeldung zum Newsletter<br/>
                    <br/>
                    Wenn Sie sich zu unserem Newsletter anmelden, verwenden wir die hierfür erforderlichen oder
                    gesondert von Ihnen mitgeteilten Daten, um Ihnen regelmäßig unseren E-Mail-Newsletter aufgrund
                    Ihrer Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO zuzusenden.<br/>
                    <br/>
                    Die Abmeldung vom Newsletter ist jederzeit möglich und kann entweder durch eine Nachricht an die
                    unten beschriebene Kontaktmöglichkeit oder über einen dafür vorgesehenen Link im Newsletter
                    erfolgen. Nach Abmeldung löschen wir Ihre E-Mail-Adresse aus der Empfängerliste, soweit Sie
                    nicht ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine
                    darüber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir
                    Sie in dieser Erklärung informieren.<br/>
                    <br/>
                    Der Newsletter wird im Rahmen einer Verarbeitung in unserem Auftrag durch einen Dienstleister
                    versendet, an den wir Ihre E-Mail-Adresse hierzu weitergeben. Dieser Dienstleister sitzt
                    innerhalb eines Landes der Europäischen Union oder des Europäischen Wirtschaftsraums.<br/>
                    <br/>
                    5. Datenverwendung bei Zahlungsabwicklung<br/>
                    <br/>
                    Identitäts- und Bonitätsprüfung bei Auswahl von Klarna Zahlungsdienstleistungen<br/>
                    <br/>
                    Wenn Sie sich für Klarnas Zahlungsdienstleistungen entscheiden, bitten wir Sie um Ihre
                    Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO, dass wir die für die Abwicklung der Zahlung
                    und eine Identitäts- und Bonitätsprüfung notwendigen Daten an Klarna übermitteln dürfen. In
                    Deutschland können für die Identitäts- und Bonitätsprüfung die in Klarnas Datenschutzerklärung
                    genannten Wirtschaftsauskunfteien eingesetzt werden.
                    Die erhaltenen Informationen über die statistische Wahrscheinlichkeit eines Zahlungsausfalls
                    verwendet Klarna für eine abgewogene Entscheidung über die Begründung, Durchführung oder
                    Beendigung des Vertragsverhältnisses.
                    Ihre Einwilligung können Sie jederzeit durch eine Nachricht an die unten stehenden Kontaktdaten
                    widerrufen. Dies kann zur Folge haben, dass wir Ihnen bestimmte Zahlungsoptionen nicht mehr
                    anbieten können. Sie können Ihre Einwilligung zu dieser Verwendung der personenbezogenen Daten
                    jederzeit auch gegenüber Klarna widerrufen.<br/>
                    <br/>
                    6. Cookies und Webanalyse<br/>
                    <br/>
                    Um den Besuch unserer Webseite attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu
                    ermöglichen, um passende Produkte anzuzeigen oder zur Marktforschung verwenden wir auf
                    verschiedenen Seiten sogenannte Cookies. Dies dient der Wahrung unserer im Rahmen einer
                    Interessensabwägung überwiegenden berechtigten Interessen an einer optimierten Darstellung
                    unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO.<br/>
                    <br/>
                    Cookies sind kleine Textdateien, die automatisch auf Ihrem Endgerät gespeichert werden. Einige
                    der von uns verwendeten Cookies werden nach Ende der Browser-Sitzung, also nach Schließen Ihres
                    Browsers, wieder gelöscht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endgerät
                    und ermöglichen uns, Ihren Browser beim nächsten Besuch wiederzuerkennen (persistente Cookies).
                    Die Dauer der Speicherung können Sie der Übersicht in den Cookie-Einstellungen Ihres Webbrowsers
                    entnehmen. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies
                    informiert werden und einzeln über deren Annahme entscheiden oder die Annahme von Cookies für
                    bestimmte Fälle oder generell ausschließen. Bei der Nichtannahme von Cookies kann die
                    Funktionalität unserer Webseite eingeschränkt sein. Jeder Browser unterscheidet sich in der Art,
                    wie er die Cookie-Einstellungen verwaltet. Diese ist in dem Hilfemenü jedes Browsers
                    beschrieben, welches Ihnen erläutert, wie Sie Ihre Cookie-Einstellungen ändern können. Diese
                    finden Sie für die jeweiligen Browser unter den folgenden Links:<br/>
                    <br/>
                    Microsoft Edge™ / Safari™ / Chrome™ / Firefox™ / Opera™<br/>
                    <br/>
                    Einsatz von Google (Universal) Analytics zur Webanalyse<br/>
                    Zur Webseitenanalyse setzt diese Webseite Google (Universal) Analytics ein. Der Webanalysedienst
                    ist ein Angebot der Google Ireland Limited, einer nach irischem Recht eingetragenen und
                    betriebenen Gesellschaft mit Sitz in Gordon House, Barrow Street, Dublin 4, Irland
                    (www.google.de). Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung
                    überwiegenden berechtigten Interessen an einer optimierten Darstellung unseres Angebots gemäß
                    Art. 6 Abs. 1 S. 1 lit. f DSGVO. Google (Universal) Analytics verwendet Methoden, die eine
                    Analyse der Benutzung der Webseite durch Sie ermöglichen, wie zum Beispiel Cookies. Die
                    automatisch erhobenen Informationen über Ihre Benutzung dieser Webseite werden in der Regel an
                    einen Server von Google in den USA übertragen und dort gespeichert. Durch die Aktivierung der
                    IP-Anonymisierung auf dieser Webseite wird dabei die IP-Adresse vor der Übermittlung innerhalb
                    der Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über
                    den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an
                    einen Server von Google in den USA übertragen und dort gekürzt. Die im Rahmen von Google
                    Analytics von Ihrem Browser übermittelte anonymisierte IP-Adresse wird grundsätzlich nicht mit
                    anderen Daten von Google zusammengeführt. Nach Zweckfortfall und Ende des Einsatzes von Google
                    Analytics durch uns werden die in diesem Zusammenhang erhobenen Daten gelöscht.<br/>
                    <br/>
                    Soweit Informationen auf Server von Google in den USA übertragen und dort gespeichert werden,
                    ist die amerikanische Gesellschaft Google LLC unter dem EU-US-Privacy Shield zertifiziert. Ein
                    aktuelles Zertifikat kann hier eingesehen werden. Aufgrund dieses Abkommens zwischen den USA und
                    der Europäischen Kommission hat letztere für unter dem Privacy Shield zertifizierte Unternehmen
                    ein angemessenes Datenschutzniveau festgestellt.<br/>
                    <br/>
                    Sie können die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite
                    bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
                    Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin
                    herunterladen und installieren: https://tools.google.com/dlpage/gaoptout?hl=de<br/>
                    <br/>
                    Alternativ zum Browser-Plugin können Sie diesen Link klicken, um die Erfassung durch Google
                    Analytics auf dieser Webseite zukünftig zu verhindern. Dabei wird ein Opt-Out-Cookie auf Ihrem
                    Endgerät abgelegt. Löschen Sie Ihre Cookies, müssen Sie den Link erneut klicken.<br/>
                    <br/>
                    7. Online-Marketing<br/>
                    <br/>
                    Google reCAPTCHA<br/>
                    Zum Zwecke des Schutzes vor Missbrauch unserer Web-Formulare sowie vor Spam nutzen wir im Rahmen
                    einiger Formulare auf dieser Webseite den Google reCAPTCHA Service. Google reCAPTCHA ist ein
                    Angebot der Google Ireland Limited, einer nach irischem Recht eingetragenen und betriebenen
                    Gesellschaft mit Sitz in Gordon House, Barrow Street, Dublin 4, Irland. (www.google.de). Durch
                    die Überprüfung einer manuellen Eingabe verhindert dieser Dienst, dass automatisierte Software
                    (sog. Bots) missbräuchliche Aktivitäten auf der Webseite ausführt. Dies dient gemäß Art. 6 Abs.
                    1 S. 1 lit. f DSGVO der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden
                    berechtigten Interessen am Schutz unserer Webseite vor Missbrauch sowie an einer störungsfreien
                    Darstellung unseres Onlineauftritts.<br/>
                    <br/>
                    Google reCAPTCHA verwendet mittels eines in der Webseite eingebundenen Codes, einem sog.
                    JavaScript, im Rahmen der Überprüfung Methoden, die eine Analyse der Benutzung der Webseite
                    durch Sie ermöglichen, wie zum Beispiel Cookies. Die automatisch erhobenen Informationen über
                    Ihre Benutzung dieser Webseite einschließlich Ihrer IP Adresse werden in der Regel an einen
                    Server von Google in den USA übertragen und dort gespeichert. Daneben werden andere, durch
                    Google Dienste in Ihrem Browser gespeicherte Cookies durch Google reCAPTCHA ausgewertet.<br/>
                    Ein Auslesen oder Speichern von personenbezogenen Daten aus den Eingabefeldern des jeweiligen
                    Formulars findet nicht statt.<br/>
                    <br/>
                    Soweit Informationen auf Server von Google in den USA übertragen und dort gespeichert werden,
                    ist die amerikanische Gesellschaft Google LLC unter dem EU-US-Privacy Shield zertifiziert. Ein
                    aktuelles Zertifikat kann hier eingesehen werden. Aufgrund dieses Abkommens zwischen den USA und
                    der Europäischen Kommission hat letztere für unter dem Privacy Shield zertifizierte Unternehmen
                    ein angemessenes Datenschutzniveau festgestellt.<br/>
                    <br/>
                    Sie können die Erfassung der durch das JavaScript, bzw. das Cookie erzeugten und auf Ihre
                    Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung
                    dieser Daten durch Google verhindern, indem sie in Ihren Browser-Einstellungen die Ausführung
                    von JavaScripten oder das Setzen von Cookies unterbinden. Bitte beachten Sie, dass dies die
                    Funktionalität unseres Webangebots für Ihre Nutzung einschränken kann.<br/>
                    <br/>
                    Weitere Informationen zu der Datenschutzpolicy von Google finden Sie hier.<br/>
                    <br/>
                    Google Fonts<br/>
                    <br/>
                    Auf dieser Webseite ist der Script Code „Google Fonts“ eingebunden. Google Fonts ist ein Angebot
                    der Google Ireland Limited, einer nach irischem Recht eingetragenen und betriebenen Gesellschaft
                    mit Sitz in Gordon House, Barrow Street, Dublin 4, Irland. (www.google.de). Dies dient der
                    Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an
                    einer einheitlichen Darstellung der Inhalte auf unserer Webseite gemäß Art. 6 Abs. 1 lit. f)
                    DSGVO. In diesem Rahmen wird eine Verbindung zwischen dem von Ihnen verwendeten Browser und den
                    Servern von Google hergestellt. Hierdurch erlangt Google Kenntnis darüber, dass über Ihre
                    IP-Adresse unsere Webseite aufgerufen wurde.<br/>
                    Soweit Informationen auf Server von Google in den USA übertragen und dort gespeichert werden,
                    ist die amerikanische Gesellschaft Google LLC unter dem EU-US-Privacy Shield zertifiziert. Ein
                    aktuelles Zertifikat kann hier eingesehen werden. Aufgrund dieses Abkommens zwischen den USA und
                    der Europäischen Kommission hat letztere für unter dem Privacy Shield zertifizierte Unternehmen
                    ein angemessenes Datenschutzniveau festgestellt. Weitergehende Informationen über die
                    Datenverarbeitung durch Google finden Sie in den Datenschutzhinweisen von Google.<br/>
                    <br/>
                    8. Social Media<br/>
                    <br/>
                    Unsere Onlinepräsenz auf Facebook, Instagram<br/>
                    <br/>
                    Unsere Präsenz auf sozialen Netzwerken und Plattformen dient einer besseren, aktiven
                    Kommunikation mit unseren Kunden und Interessenten. Wir informieren dort über unsere Produkte
                    und laufende Sonderaktionen.<br/>
                    Bei dem Besuch unserer Onlinepräsenzen in sozialen Medien können Ihre Daten für Marktforschungs-
                    und Werbezwecke automatisch erhoben und gespeichert werden. Aus diesen Daten werden unter
                    Verwendung von Pseudonymen sog. Nutzungsprofile erstellt. Diese können verwendet werden, um z.B.
                    Werbeanzeigen innerhalb und außerhalb der Plattformen zu schalten, die mutmaßlich Ihren
                    Interessen entsprechen. Zu diesem Zweck werden im Regelfall Cookies auf Ihrem Endgerät
                    eingesetzt. In diesen Cookies werden das Besucherverhalten und die Interessen der Nutzer
                    gespeichert. Dies dient gem. Art. 6 Abs. 1 lit. f. DSGVO der Wahrung unserer im Rahmen einer
                    Interessensabwägung überwiegenden berechtigten Interessen an einer optimierten Darstellung
                    unseres Angebots und effektiver Kommunikation mit den Kunden und Interessenten. Falls Sie von
                    den jeweiligen Social-Media Plattformbetreibern um eine Einwilligung (Einverständnis) in die
                    Datenverarbeitung gebeten werden, z.B. mit Hilfe einer Checkbox, ist die Rechtsgrundlage der
                    Datenverarbeitung Art. 6 Abs. 1 lit. a DSGVO.<br/>
                    Soweit die vorgenannten Social-Media Plattformen ihren Hauptsitz in den USA haben, gilt
                    Folgendes: Für die USA liegt ein Angemessenheitsbeschluss der Europäischen Kommission vor.
                    Dieser geht zurück auf den EU-US Privacy Shield. Ein aktuelles Zertifikat für das jeweilige
                    Unternehmen kann hier eingesehen werden.<br/>
                    Die detaillierten Informationen zur Verarbeitung und Nutzung der Daten durch die Anbieter auf
                    deren Seiten sowie eine Kontaktmöglichkeit und Ihre diesbezüglichen Rechte und
                    Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre, insbesondere Widerspruchsmöglichkeiten
                    (Opt-Out), entnehmen Sie bitte den unten verlinkten Datenschutzhinweisen der Anbieter. Sollten
                    Sie diesbezüglich dennoch Hilfe benötigen, können Sie sich an uns wenden.<br/>
                    <br/>
                    Facebook: https://www.facebook.com/about/privacy/<br/>
                    Die Datenverarbeitung erfolgt auf Grundlage einer Vereinbarung zwischen gemeinsam
                    Verantwortlichen gemäß Art. 26 DSGVO, die Sie hier einsehen können.<br/>
                    Weitere Informationen zur Datenverarbeitung im Rahmen des Besuchs einer Facebook Fanpage
                    (Informationen zu Insights-Daten) finden Sie hier.<br/>
                    <br/>
                    Instagram: https://help.instagram.com/519522125107875<br/>
                    <br/>
                    Widerspruchsmöglichkeit (Opt-Out):<br/>
                    <br/>
                    Facebook: https://www.facebook.com/settings?tab=ads<br/>
                    <br/>
                    Instagram: https://help.instagram.com/519522125107875<br/>
                    <br/>
                    9. Kontaktmöglichkeiten und Ihre Rechte<br/>
                    <br/>
                    Als Betroffener haben Sie folgende Rechte:<br/>
                    <br/>
                    gemäß Art. 15 DSGVO das Recht, in dem dort bezeichneten Umfang Auskunft über Ihre von uns
                    verarbeiteten personenbezogenen Daten zu verlangen;
                    gemäß Art. 16 DSGVO das Recht, unverzüglich die Berichtigung unrichtiger oder Vervollständigung
                    Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;
                    gemäß Art. 17 DSGVO das Recht, die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten
                    zu verlangen, soweit nicht die weitere Verarbeitung
                    zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
                    zur Erfüllung einer rechtlichen Verpflichtung;
                    aus Gründen des öffentlichen Interesses oder
                    zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;
                    gemäß Art. 18 DSGVO das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
                    zu verlangen, soweit
                    die Richtigkeit der Daten von Ihnen bestritten wird;
                    die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen;
                    wir die Daten nicht mehr benötigen, Sie diese jedoch zur Geltendmachung, Ausübung oder
                    Verteidigung von Rechtsansprüchen benötigen oder
                    Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
                    gemäß Art. 20 DSGVO das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben,
                    in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die
                    Übermittlung an einen anderen Verantwortlichen zu verlangen;<br/>
                    gemäß Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde zu beschweren. In der Regel
                    können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder
                    Arbeitsplatzes oder unseres Unternehmenssitzes wenden.<br/>
                    Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei
                    Auskünften, Berichtigung, Einschränkung oder Löschung von Daten sowie Widerruf erteilter
                    Einwilligungen oder Widerspruch gegen eine bestimmte Datenverwendung wenden Sie sich bitte
                    direkt an uns über die Kontaktdaten in unserem Impressum.
                </DialogContentText>
            </DialogContent>
        </>
    )
}

export default Datenschutz;