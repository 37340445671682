import {FC, ReactNode, useEffect} from "react";

// @ts-ignore
import {Fancybox as NativeFancybox} from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

interface FancyboxProps {
    delegate?: string;
    options: any;
    children: ReactNode;
}

const Fancybox: FC<FancyboxProps> = (props) => {
    const delegate = props.delegate || "[data-fancybox]";
    const options = props.options;

    useEffect(() => {
        NativeFancybox.bind(delegate, options || {});

        return () => {
            NativeFancybox.destroy();
        };
    }, [delegate, options]);

    return <>{props.children}</>;
}

export default Fancybox;
