import React, {useEffect, useRef, useState} from 'react';
import logo from './assets/logo.jpg';
import './App.css';
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Tooltip,
    Typography,
} from "@material-ui/core";
import {Close, Email, Phone} from "@material-ui/icons";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import Datenschutz from "./components/Datenschutz";
import Impressum from "./components/Impressum";
import {IFile, listFiles} from "./utils/drive.api.utils";
import Fancybox from "./components/Fancybox";

const App = () => {
    // const [eventOpen, setEventOpen] = useState(false);
    const [imprintOpen, setImprintOpen] = useState(false);
    const [privacyOpen, setPrivacyOpen] = useState(false);
    const [images, setImages] = useState<IFile[]>([]);
    const [holidayInfoOpen, setHolidayInfoOpen] = useState(false);

    const [isLoadingGallery, setIsLoadingGallery] = useState(false);

    const handleWhatsAppClick = () => {
        window.open("https://wa.me/4981673251349");
        // const message = 'Hey! Bitte trag mich in den Verteiler für zukünftige Veranstaltungen ein.'
        // if (isMobileOrTablet()) {
        //     openUrl('api', message)
        // } else {
        //     openUrl('web', message)
        // }
    }

    const handleOpenGallery = () => {
        setIsLoadingGallery(true);
        listFiles().then(images => {
            setImages(images)
        }).finally(() => {
            setIsLoadingGallery(false);
        });
    }

    // stuff for gallery button
    const ref = useRef<HTMLButtonElement>(null);
    const galleryButtonProps = images.length ? {
        "data-fancybox": "gallery",
        "data-src": images[0]?.original,
    } : {
        onClick: handleOpenGallery
    }

    // open gallery after images have been loaded
    useEffect(() => {
        if (images.length) {
            ref.current?.click();
        }
    }, [images]);

    return (
        <div className="App">
            <Box
                width={400}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                textAlign={'center'}
            >
                <Box position={'relative'}>
                    <Avatar variant={"circular"} src={logo} className="logo" alt="Café Bellini Freising"/>
                </Box>
                <div className={'bottom'}>
                    {/*<p className={'slogan'}>*/}
                    {/*    Café Bellini Freising*/}
                    {/*</p>*/}
                    <address className={'address'}>
                        <br/>
                        Untere Hauptstraße 31<br/>
                        85354 Freising<br/>
                        <br/>
                        <a href={'tel:+4981619102651'} title={"Nummer anrufen"}><Phone className={'whatsappIcon'}/> +49
                            (0)8161 9102651</a><br/>
                        <a href={'mailto:salisch.gastro@gmail.com'} title={"E-Mail schreiben"}><Email
                            className={'whatsappIcon'}/> salisch.gastro@gmail.com</a><br/>
                    </address>
                    <Box pt={4}>
                        <Typography variant={"subtitle1"}>Öffnungszeiten:</Typography>
                        <Typography variant={"body2"}>
                            <br/>
                            Mo - Mi von 09:00 – 18:00<br/>
                            Do - Fr von 09:00 – 23:00<br/>
                            Samstag von 09:00 – 18:00<br/>
                            Sonntag ist Ruhetag
                        </Typography>
                    </Box>
                    <Box mt={4} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
                        {/*<Tooltip title={'Veranstaltungen im August'}>*/}
                        {/*    <IconButton onClick={() => window.open('/events.pdf')}>*/}
                        {/*        <EventNoteIcon/>*/}
                        {/*    </IconButton>*/}
                        {/*</Tooltip>*/}
                        {/*<Box mb={1}>*/}
                        {/*    <Tooltip title={'Speisekarte als PDF öffnen'} placement={'right'}>*/}
                        {/*        <Button*/}
                        {/*            variant={"outlined"}*/}
                        {/*            startIcon={<RestaurantMenu/>}*/}
                        {/*            onClick={() => window.open('/speise-und-getraenkekarte.pdf')}*/}
                        {/*        >*/}
                        {/*            Speisekarte*/}
                        {/*        </Button>*/}
                        {/*    </Tooltip>*/}
                        {/*</Box>*/}
                        {/*<Box mb={1}>*/}
                        {/*    <Tooltip title={'Schreib` uns per WhatsApp'} placement={'right'}>*/}
                        {/*        <Button*/}
                        {/*            variant={"outlined"}*/}
                        {/*            startIcon={<WhatsApp/>}*/}
                        {/*            onClick={handleWhatsAppClick}*/}
                        {/*        >*/}
                        {/*            Kontakt*/}
                        {/*        </Button>*/}
                        {/*    </Tooltip>*/}
                        {/*</Box>*/}
                        <Box mb={3}>
                            <Fancybox options={{infinite: false}}>
                                <Tooltip title={'Bildergallery öffnen'} placement={'right'}>
                                    <Button
                                        variant={"outlined"}
                                        ref={ref}
                                        startIcon={isLoadingGallery ? <CircularProgress size={24}/> : <CameraAltIcon/>}
                                        {...galleryButtonProps}
                                    >
                                        Bilder
                                    </Button>
                                </Tooltip>
                                {images.slice(1).map((image) => {
                                    return (
                                        <div
                                            key={image.original}
                                            data-fancybox="gallery"
                                            data-src={image.original}
                                            title={image.description}
                                            style={{display: "none"}}
                                        />
                                    )
                                })}
                            </Fancybox>
                        </Box>
                    </Box>
                </div>
            </Box>

            <Box className={'impressumsLink'} display={'flex'}>
                <Button onClick={() => setImprintOpen(true)} variant={"text"}>
                    Impressum
                </Button>
                <Button onClick={() => setPrivacyOpen(true)} variant={"text"}>
                    Datenschutz
                </Button>
            </Box>

            {/*<Dialog open={eventOpen} onClose={() => setEventOpen(false)} className={'dialog'}>*/}
            {/*    <IconButton onClick={() => setEventOpen(false)} className={'dialogClose'}>*/}
            {/*        <Close/>*/}
            {/*    </IconButton>*/}
            {/*    <DialogContent>*/}
            {/*        <img src={eventcalendar} alt={"Veranstaltungen im Juli"} className={"eventImage"}/>*/}
            {/*    </DialogContent>*/}
            {/*    <DialogActions>*/}
            {/*        <Button*/}
            {/*            startIcon={<WhatsApp/>}*/}
            {/*            onClick={handleWhatsAppClick}*/}
            {/*        >*/}
            {/*            schreib uns*/}
            {/*        </Button>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}

            <Dialog open={imprintOpen} onClose={() => setImprintOpen(false)} className={'dialog'}>
                <IconButton onClick={() => setImprintOpen(false)} className={'dialogClose'}>
                    <Close/>
                </IconButton>
                <Impressum/>
                <DialogActions>
                    <Button onClick={() => setImprintOpen(false)}>
                        schließen
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={privacyOpen} onClose={() => setPrivacyOpen(false)} className={'dialog'}>
                <IconButton onClick={() => setPrivacyOpen(false)} className={'dialogClose'}>
                    <Close/>
                </IconButton>
                <Datenschutz/>
                <DialogActions>
                    <Button onClick={() => setPrivacyOpen(false)}>
                        schließen
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={holidayInfoOpen} onClose={() => setHolidayInfoOpen(false)} className={'dialog'}>
                <IconButton onClick={() => setHolidayInfoOpen(false)} className={'dialogClose'}>
                    <Close/>
                </IconButton>
                <DialogContent>
                    <Box mt={5}>
                        <Typography variant={"body2"} gutterBottom>
                            Das Salisch hat bis zum 16 Januar Betriebsurlaub.
                        </Typography>
                        <Typography variant={"body2"} gutterBottom>
                            Wir freuen uns auf Euch im neuen Jahr!
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setHolidayInfoOpen(false)}>
                        ok
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}

export default App;
